
.App{
  background-image: url('assets/background.png');
  background-size: cover;
  background-position: center;
  background-color: #282c34;
}
.Body{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-size: cover;
  background-position: center;
  animation: fade-in 2s ease-in;

}

.App-header{
  background-color: transparent;
  min-height: 20vh;
  align-items: right;
  justify-content: right;
  animation: fade-in 1s ease-in;

}
.App-Button{
  rotate: 2deg;
  background-image: url('assets/music_bg.png');
  background-size: cover;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 0px;
  padding: 40px;
  margin: 50px;
  font-size: 20px;
  font-family: 'Indie Flower', cursive;
  color: rgb(0, 0, 0);
  -webkit-text-stroke: white;
  -webkit-text-stroke-width: 0.5px;
  cursor: pointer;
  transition-duration: 0.4s;
  animation: fade-in 1s ease-in;
  align-content: center;
}

.Letter-Container {
  flex: 1;
  justify-content: 'center';
  padding-top: 30;
  padding: 8;
  align-items: 'center';
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 5%;
}
.Letter-Title{
  font-size: 50px;
  color: #61dafb;
  margin: 10px;
  animation: fade-in 2s ease-in;
  color: white;
  -webkit-text-stroke: black;
  font-family: 'Indie Flower', cursive;
  -webkit-text-stroke-width: 1px;
}
.Letter-Text{
  font-size: 20px;
  margin: 60px;
  animation: fade-in 2s ease-in;
  text-align: center;
  font-family: 'Indie Flower', cursive;
  font-size: 25px;
  color: white;
  -webkit-text-stroke: black;
  -webkit-text-stroke-width: 1px;
}

.Password{
  font-size: 20px;
  min-height: 100vh;
  text-align: center;
  font-size: 25px;
  background-color: #282c34;
  background-size: cover;
}

.Login-Input{
  background-color: gray;
  border: 2px white;
  border-radius: 10px;
  /*white border on hovering inputfield*/
  border-color: white;

  padding: 10px;
  margin: 50px;
  font-size: 40px;
  font-family: 'Indie Flower', cursive;
  cursor: pointer;
  align-content: center;
  

}
.Login-Button{
  background-color: gray;
  border: 2px solid transparent;
  border-radius: 0px;
  padding: 5px;
  margin: 50px;
  font-size: 50px;
  font-family: 'Indie Flower', cursive;
  color: black;
  cursor: pointer;
  transition-duration: 0.4s;
  align-content: center;

}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}